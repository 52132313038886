import config from "tsi-common-react/src/config";
import { strings } from "tsi-common-react/src/localization";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

config.update({
    ENABLE_CHECKOUT_CAPTCHA: true,
    METRICS_API: getPageSetting("metrics-api"),
    REVIEWS_API: getPageSetting("reviews-api"),
    REVIEWS_DISCLOSURE_SETTINGS: getPageSetting("reviews-disclosures-settings"),
    GOOGLE_MAPS_API: getPageSetting("google-maps-api-key"),
});

strings.update({
    WEBSITE_SUPPORT_NUMBER: getPageSetting(
        "support-website-phone-number-display",
    ),
    WEBSITE_SUPPORT_NUMBER_LINK: getPageSetting(
        "support-website-phone-number-link",
    ),
    WEBSITE_SUPPORT_CONTACT_HOURS: getPageSetting("support-contact-hours"),
    REJECT_MSG_CYBERSOURCE:
        `Your payment has been declined. Please take the following steps to complete your order: <ul>` +
        `<li>Step 1 - Verify that your payment and billing information is accurate, correct any errors and resubmit your order</li>` +
        `<li>Step 2 - If you believe your payment and billing information is correct, please contact your financial institution</li>` +
        `<li>Step 3 - If you are still experiencing issues, you may call us at WEBSITE_SUPPORT_NUMBER and provide reference ` +
        `number ORDER_ID</li></ul>`,

    CHECKOUT_ERROR_FALLBACK:
        `An error occurred while placing your order. Please verify the information is correct, and resubmit your order. ` +
        `If you are still experiencing issues, you may call us at WEBSITE_SUPPORT_NUMBER and provide reference number ORDER_ID.`,
});
